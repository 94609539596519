<template>
  <b-modal
    id="modal-detailt-order"
    centered
    title="Detalle del pedido"
  >
    <div v-if="record">
      <b-row
        class="mb-2"
      >
        <b-col>
          <div class="box text-center">
            <div
              class="title"
            >
              {{
                record.patient
              }}
            </div>
            <div class="subtitle">
              <span><strong>Tipo de dieta: </strong></span>
              <span>{{ record.diet.name }}
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="4">
          <div class="title2">
            HABITACIÒN
          </div>
          <div class="subtitle">
            201-A
          </div>
        </b-col>
        <b-col cols="4">
          <div class="title2">
            FECHA
          </div>
          <div class="subtitle">
            {{ record.date }}
          </div>
        </b-col>
        <b-col
          cols="4"
          class="p-0"
        >
          <div class="title2">
            HORA DE ENTREGA
          </div>
          <div class="subtitle">
            {{ record.hour }}
          </div>
        </b-col>
      </b-row>
      <b-row
        class="mb-2"
      >
        <b-col
          cols="12"
        >
          <div class="title2">
            Platos y acompañamientos
          </div>
        </b-col>
        <b-col cols="12">
          <b-row>
            <b-col
              v-for="(item, index) in record.items"
              :key="index"
              cols="4"
            >
              <card-dishe :item="item" />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4">
          <div class="title2">
            Merienda
          </div>
          <b-badge variant="primary">
            {{ record.snack == '1' ? 'Sí' : 'No' }}
          </b-badge>
        </b-col>
        <b-col cols="8">
          <div class="title2">
            Observaciones
          </div>
          <div class="subtitle">
            {{ record.observations ? record.observations : 'Sin observaciones' }}
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <div class="box text-center">
            <b-row class="d-flex align-items-center">
              <b-col cols="4">
                <img
                  src="@/assets/images/QR.png"
                  alt=""
                  width="100%"
                >
              </b-col>
              <b-col cols="8">
                <div
                  class="title"
                >
                  {{
                    record.patient
                  }}
                </div>
                <div class="subtitle">
                  <span><strong>HABITACIÒN </strong></span>
                  <span>201-A</span>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
    <template #modal-footer="{ close }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        v-b-modal.modal-seconday
        variant="outline-secondary"
        @click="close()"
      >
        Cerrar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BButton,
  BRow,
	BCol,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CardDishe from './CardDishe'

export default {
  components: {
    BModal,
    BButton,
    BRow,
    BCol,
    BBadge,
    CardDishe,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      show: true,
    }
  },
}
</script>

<style lang="scss" scoped>
.box {
  border: 1px solid grey;
  padding: 1rem;
  border-radius: .6rem;
}

.title {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 5px;
  color: #5E5878;
}

.title2 {
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 5px;
  color: #5E5878;
}

.title3 {
  font-size: 14px;
  margin-bottom: 5px;
  color: #5E5878;
}

.subtitle span{
  font-size: 14px;
  color: #5E5878;
}

</style>
