<template>
  <b-popover
    :target="item.id"
    title="Información"
    triggers="hover"
  >
    <div class="mb-2">
      <div class="title">
        Ingredientes
      </div>
      <template v-if="item.ingredients">
        <div
          v-for="(ingredient, index) in item.ingredients"
          :key="index"
          class="title2"
        >
          <feather-icon icon="CheckCircleIcon" />
          {{ ingredient.title }}
        </div>
      </template>
      <template v-else>
        <div class="title2 text-center">
          No tiene ingredientes registrados.
        </div>
      </template>
    </div>
    <div class="">
      <div class="title">
        Información Nutricional
      </div>
      <template v-if="item.nutritionalinformation.length > 0">
        <div
          v-for="(info, index) in item.nutritionalinformation"
          :key="index"
          class="title2"
        >
          <div
            v-for="(inf) in JSON.parse(info.data.nutritional_values)"
            :key="inf.title"
          >
            <v-icon
              name="check-circle"
            />
            {{ inf.title + ' ' + inf.value }}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="title2 text-center">
          No tiene información nutricional registrada.
        </div>
      </template>
    </div>
  </b-popover>
</template>

<script>
import {
  BPopover,
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BPopover,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
  .title {
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 5px;
    color: #5E5878;
  }

  .title2 {
    font-size: 14px;
    margin-bottom: 5px;
    color: #5E5878;
  }
</style>
