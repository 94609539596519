<template>
  <div>
    <b-card
      no-body
      class="card__ p-1"
    >
      <div class="d-flex justify-content-end">
        <v-icon
          name="calendar-alt"
          class=""
          scale="1"
        />
        <span class="title5 ml-1">{{ current_day }}</span>
      </div>
      <base-actions-table
        :columns="fields"
        :records="records"
        :module="module"
        :busy="isBusy"
      >
        <!-- actions -->
        <template v-slot:extendedactions="data">
          <b-button
            v-if="hasPermission(`${module}.details`)"
            v-b-tooltip.hover.top="'Ver detalles'"
            size="sm"
            variant="success"
            class="btn-icon mr-25"
            @click="details(data.data.item)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
        </template>
      </base-actions-table>
    </b-card>
    <detailt-order :record="record" />
  </div>
</template>

<script>
import {
	BCard,
	BButton,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import BaseActionsTable from '@/components/base/BaseActionsTable'
import DetailtOrder from '../components/DetailtOrder'

moment.locale('es')

export default {
  inject: ['orderRepository'],
  components: {
    BCard,
    BButton,
    BaseActionsTable,
    DetailtOrder,
  },

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      record: null,
      records: [],
      current_day: (moment(), moment()).format('YYYY-MM-DD'),
      isBusy: false,
      fields: [
				{
					key: 'patient',
					label: 'Paciente',
					sortable: true,
				},
				{
					key: 'habitacion',
					label: 'Habitaciòn',
					sortable: true,
				},
				{
					key: 'type_menu',
					label: 'Tipo de menú',
					sortable: true,
				},
				{
					key: 'tipo_diet',
					label: 'Tipo de dieta',
					sortable: true,
				},
				{
					key: 'date',
					label: 'Fecha',
					sortable: true,
				},
				{
					key: 'hour',
					label: 'Hora',
					sortable: true,
				},
				{
					key: 'delivery_person',
					label: 'Transportador',
					sortable: true,
				},
				{
					key: 'actions',
					label: 'Acciones',
				},
			],
      module: 'ordernutritionist',
    }
  },

  methods: {
    details(item) {
			const me = this
			me.record = item.data
			this.$bvModal.show('modal-detailt-order')
		},
    async loadOrdersCurrentDay() {
			const me = this
			try {
        me.isBusy = true
				const res = await me.orderRepository.getAllByDate(me.current_day)
				const { data } = res
        data.forEach(el => {
          let type_menu
          if (el.menu.type_menu == '1') {
            type_menu = 'Desayuno'
          } else if (el.menu.type_menu == '2') {
            type_menu = 'Almuerzo'
          } else {
            type_menu = 'Cena'
          }
          me.records.push({
            ...el,
            patient: el.patient.first_name + ' ' + el.patient.last_name,
            tipo_diet: el.diet.name,
            type_menu: type_menu,
            delivery_person: el.delivery_person != '' ? el.delivery_person : 'Sin asignar',
          })
				})
			} catch (error) {
				// console.log(error)
			} finally {
        me.isBusy = false
      }
		},
  },

}
</script>

<style lang="scss" scoped>
.card__ {
  box-shadow: 0 5px 11px 0 rgba(145, 144, 144, 0.3), 0 4px 15px 0 rgba(133, 132, 132, 0.15);
}

.title {
  font-size: 18px;
  font-weight: 700;
  color: #636363;
}
</style>
