<template>
  <b-overlay :show="isProcessing">
    <b-row>
      <b-col
        md="4"
        class="align-self-center pl-3"
      >
        <slot name="left-panel" />
      </b-col>
      <b-col
        md="8"
        class="my-1 pr-3"
      >
        <b-form-group
          v-if="filtering"
          label="Buscar"
          label-cols-sm="3"
          label-align-sm="right"
          label-for="filterInput"
          class="mb-0 w-75 float-right"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder=""
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Limpiar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :fields="columns"
          :items="records"
          :filter="filter"
          :per-page="dataPerPage"
          :current-page="pagination.currentPage"
          :busy="busy"
          show-empty
          :filter-included-fields="filterOn"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Cargando datos...</strong>
            </div>
          </template>
          <template #empty>
            <h4 class="text-center py-2">
              No hay registros
            </h4>
          </template>
          <slot
            v-for="(_, name) in $slots"
            :slot="name"
            :name="name"
          />
          <template
            v-for="(_, name) in $scopedSlots"
            :slot="name"
            slot-scope="slotData"
          >
            <slot
              :name="name"
              v-bind="slotData"
            />
          </template>
        </b-table>
      </b-col>
      <template v-if="paginateServe">
        <b-col
          md="6"
          class="pl-3"
        >
          <b-form-group
            class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">Páginas</label>
            <b-form-select
              v-model="paginationServeOptions.perPage"
              size="sm"
              :options="pagination.pageOptions"
              class="w-50"
              @change="changeLimit"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          class="pr-3 pb-2 d-flex align-items-center justify-content-end"
        >

          <nav v-if="paginationServe && records.length > 0">
            <ul class="pagination">
              <li
                class="page-item"
                :class="{'disabled' : paginationServeOptions.currentPage === 1}"
              >
                <a
                  class="page-link"
                  href="#"
                  @click.prevent="changePage(paginationServeOptions.currentPage - 1)"
                >&laquo;</a>
              </li>
              <li
                v-for="page in pagesNumber"
                :key="page"
                class="page-item"
                :class="{'active': page == paginationServe.meta.current_page}"
              >
                <a
                  href="javascript:void(0)"
                  class="page-link"
                  @click.prevent="changePage(page)"
                >{{ page }}</a>
              </li>
              <li
                class="page-item"
                :class="{'disabled': paginationServeOptions.currentPage === paginationServe.meta.last_page }"
              >
                <a
                  class="page-link"
                  href="#"
                  @click.prevent="changePage(paginationServeOptions.currentPage + 1)"
                >&raquo;</a>
              </li>
            </ul>
          </nav>
        </b-col>
      </template>
      <template v-else>
        <b-col
          md="6"
          class="pl-3"
        >
          <b-form-group
            class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">Páginas</label>
            <b-form-select
              v-model="pagination.perPage"
              size="sm"
              :options="pagination.pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          class="pr-3 pb-2"
        >
          <b-pagination
            v-model="pagination.currentPage"
            :total-rows="pagination.totalRows"
            :per-page="pagination.perPage"
            align="right"
            size="sm"
            class="my-0"
          />
        </b-col>
      </template>
    </b-row>
  </b-overlay>
</template>

<script>
	import {
		BTable,
		BCol,
		BRow,
		BFormGroup,
		BFormInput,
		BInputGroup,
		BInputGroupAppend,
		BButton,
		BPagination,
		BFormSelect,
    BSpinner,
    BOverlay,
	} from 'bootstrap-vue'

	export default {
		name: 'BaseTable',
		components: {
			BTable,
			BCol,
			BRow,
			BFormGroup,
			BFormInput,
			BInputGroup,
			BInputGroupAppend,
			BButton,
			BPagination,
			BFormSelect,
      BSpinner,
      BOverlay,
		},
		props: {
      paginateServe: {
        type: Boolean,
        default: false,
      },
      paginationServe: {
        type: Object,
        default: () => ({
        }),
      },
			columns: {
				type: Array,
				default: () => [],
			},
			records: {
				type: Array,
				default: () => [],
			},
			filtering: {
				type: Boolean,
				default: true,
			},
			paginate: {
				type: Boolean,
				default: true,
			},
			perPageDropDown: {
				type: Boolean,
				default: true,
			},
      busy: {
        type: Boolean,
        default: false,
      },
      isProcessing: {
        type: Boolean,
        default: false,
      },
      search: {
        type: String,
        default: null,
      },
		},
		data() {
			return {
				filter: this.search,
				pagination: {
					perPage: 10,
					pageOptions: [10, 20, 50],
					totalRows: 1,
					currentPage: 1,
				},
        paginationServeOptions: {
          currentPage: 1,
          offset: 4,
          perPage: 10,
          sortedColumn: this.columns[0],
          order: 'asc',

        },
				filterOn: [],
        sortDesc: false,
        sortDirection: 'asc',
			}
		},
		computed: {
			dataPerPage() {
				const me = this
        if (me.paginateServe) {
          return me.records.length
        }

				return me.paginate ? me.pagination.perPage : me.records.length
			},

      pagesNumber() {
        const me = this
        console.log(me.paginateServe)

        if (!me.paginateServe) {
          return null
        }

        if (!this.paginationServe.meta.to) {
          return []
        }
        let from = this.paginationServe.meta.current_page - this.paginationServeOptions.offset
        if (from < 1) {
          from = 1
        }
        let to = from + (this.paginationServeOptions.offset * 2)
        if (to >= this.paginationServe.meta.last_page) {
          to = this.paginationServe.meta.last_page
        }
        const pagesArray = []
        // eslint-disable-next-line no-plusplus
        for (let p = from; p <= to; p++) {
          pagesArray.push(p)
        }
        console.log(pagesArray)
        return pagesArray
      },

		},
    watch: {
      search(newData) {
        this.filter = newData
      },
    },
		mounted() {
      console.log(this.paginationServe)

      this.pagination.totalRows = this.records.length
		},
		methods: {
			onFiltered(filteredItems) {
				this.totalRows = filteredItems.length
				this.pagination.currentPage = 1
			},
      changePage(pageNumber) {
        console.log('pageNumber', pageNumber)
        console.log('currentPage', this.paginationServe)

        if (pageNumber === this.paginationServe.meta.current_page) {
          return
        }
        this.paginationServeOptions.currentPage = pageNumber
        this.$emit('change-page', pageNumber, this.paginationServeOptions.perPage)
      },
      changeLimit(limit) {
        console.log('limit', limit)
        this.paginationServeOptions.perPage = limit
        this.$emit('change-limit', limit)
      },
		},
	}
</script>

<style lang="scss" scoped></style>
