import { render, staticRenderFns } from "./BaseActionsTable.vue?vue&type=template&id=69e4f500&scoped=true&"
import script from "./BaseActionsTable.vue?vue&type=script&lang=js&"
export * from "./BaseActionsTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69e4f500",
  null
  
)

export default component.exports