<template>
  <base-table
    :columns="columns"
    :records.sync="records"
    :filtering="filtering"
    :search="search"
    :paginate="paginate"
    :per-page-drop-down="perPageDropDown"
    :busy="busy"
    :is-processing="isProcessing"
    :paginate-serve="paginateServe"
    :pagination-serve="paginationServe"
    @change-page="handleChangePage"
    @change-limit="handleChangeLimit"
  >
    <slot
      v-for="(_, name) in $slots"
      :slot="name"
      :name="name"
    />
    <template
      v-for="(_, name) in $scopedSlots"
      :slot="name"
      slot-scope="slotData"
    >
      <slot
        :name="name"
        v-bind="slotData"
      />
    </template>
    <template
      v-slot:left-panel
    >
      <b-button
        v-if="hasPermission(`${module}.new`)"
        variant="success"
        size="sm"
        class="bg-daren-2"
        @click="() => $emit('creating')"
      >
        <feather-icon
          icon="PlusIcon"
        />
        Nuevo
      </b-button>
    </template>
    <template #cell(actions)="data">
      <div class="d-flex align-items-center">
        <slot
          name="extendedactions"
          :data="data"
        >
          &nbsp;
        </slot>
        <b-button
          v-if="hasPermission(`${module}.edit`)"
          v-b-tooltip.hover.top="'Editar'"
          variant="primary"
          class="btn-icon mr-25"
          size="sm"
          @click.prevent="() => $emit('editing', data.item)"
        >
          <feather-icon
            icon="EditIcon"
          />
        </b-button>
        <b-button
          v-if="hasPermission(`${module}.delete`)"
          v-b-tooltip.hover.top="'Eliminar'"
          variant="danger"
          class="btn-icon mr-25"
          size="sm"
          @click.prevent="() => $emit('deleting', data.item)"
        >
          <feather-icon
            icon="Trash2Icon"
          />
        </b-button>
      </div>
    </template>
  </base-table>
</template>

<script>
  import {
    BButton,
    VBTooltip,
  } from 'bootstrap-vue'
  import BaseTable from '@/components/base/BaseTable'
  import PermissionMixin from '@/mixins/PermissionMixin'

  export default {
    name: 'BaseActionsTable',

    components: {
      BButton,
      BaseTable,
    },

    directives: {
      'b-tooltip': VBTooltip,
    },

    mixins: [PermissionMixin],

    props: {
      paginateServe: {
        type: Boolean,
        default: false,
      },
      paginationServe: {
        type: Object,
        default: () => ({
        }),
      },
      columns: {
        type: Array,
        default: () => [],
      },
      records: {
        type: Array,
        default: () => [],
      },
      filtering: {
        type: Boolean,
        default: true,
      },
      paginate: {
        type: Boolean,
        default: true,
      },
      perPageDropDown: {
        type: Boolean,
        default: true,
      },
      busy: {
        type: Boolean,
        default: false,
      },
      isProcessing: {
        type: Boolean,
        default: false,
      },
      module: {
        type: String,
        default: '',
      },
      search: {
        type: String,
        default: null,
      },
    },
    methods: {
      handleChangePage(page, limit) {
        this.$emit('change-page', page, limit)
      },
      handleChangeLimit(limit) {
        this.$emit('change-limit', limit)
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
