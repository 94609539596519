import store from '@/store'

const methods = {
	hasPermission(permissions) { // module.action
		const modulesPermissions = store.getters['session/getLoggedUser'].permissions
		const [module, permission] = permissions.split('.')
		const allowedModule = modulesPermissions.find(item => Object.keys(item).includes(module))

		return allowedModule && allowedModule[module].includes(permission)
	},
}

export default {
	methods,
}
