const API_URL = process.env.VUE_APP_API_URL
const API_VERSION = process.env.VUE_APP_API_VERSION

const API_ROUTES = {
	dishe: {
		get: `${API_URL}/${API_VERSION}/food`,
		find: `${API_URL}/${API_VERSION}/food`,
		save: `${API_URL}/${API_VERSION}/food`,
		delete: `${API_URL}/${API_VERSION}/food`,
		update: `${API_URL}/${API_VERSION}/food/update`,
	},
	accompaniment: {
		get: `${API_URL}/${API_VERSION}/food`,
		save: `${API_URL}/${API_VERSION}/food`,
		delete: `${API_URL}/${API_VERSION}/food`,
		update: `${API_URL}/${API_VERSION}/food/update`,
	},
	infoNutritional: {
		save: `${API_URL}/${API_VERSION}/food-nutritional-information`,
	},
	menus: {
		get: `${API_URL}/${API_VERSION}/menu`,
		find: `${API_URL}/${API_VERSION}/menu`,
		save: `${API_URL}/${API_VERSION}/menu`,
		delete: `${API_URL}/${API_VERSION}/menu`,
		update: `${API_URL}/${API_VERSION}/menu/update`,
	},
	images: {
		image: `${API_URL}/storage/`,
	},
	type_diet: {
		get: `${API_URL}/${API_VERSION}/diets`,
	},
	order: {
		get: `${API_URL}/${API_VERSION}/menu-order`,
	},
	orderSchedule: {
		get: `${API_URL}/${API_VERSION}/times-menu-order`,
		save: `${API_URL}/${API_VERSION}/times-menu-order`,
		delete: `${API_URL}/${API_VERSION}/times-menu-order`,
		update: `${API_URL}/${API_VERSION}/times-menu-order`,
	},
}

export { API_ROUTES }
