<template>
  <div>
    <b-card
      :id="item.id"
      no-body
      class="overflow-hidden box"
    >
      <b-row no-gutters>
        <b-col cols="12">
          <template v-if="item.image !== null">
            <b-card-img-lazy
              :src="loadImage(item.image)"
              :alt="item.name"
              class="rounded-0 img__"
            />
          </template>
          <template v-else>
            <b-card-img-lazy
              src="@/assets/images/default.png"
              :alt="item.name"
              class="rounded-0 img__"
            />
          </template>
        </b-col>
        <b-col
          cols="12"
          class="text-center"
        >
          <div class="title2">
            {{ item.name }}
          </div>
        </b-col>
      </b-row>
    </b-card>
    <info-popover :item="item" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardImgLazy,
} from 'bootstrap-vue'
import InfoPopover from './InfoPopover'
import { API_ROUTES } from '../config/routes/api'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardImgLazy,
    InfoPopover,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  methods: {
    loadImage(image) {
      return API_ROUTES.images.image + image
    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  border: 1px solid #5E5878;
  border-radius: .6rem;
  height: 140px;
}

.title {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 8px;
  color: #5E5878;
}

.title2 {
  font-size: 12px;
  margin-bottom: 5px;
  color: #5E5878;
}

.img__ {
  height: 90px !important;
  margin-bottom: 5px !important;
}
</style>
